<template>
    <RegistroNuevaEmpresaComponent />
  </template>
  
  <script>
  export default {
    components: {
      RegistroNuevaEmpresaComponent: () =>
        import(
          "../../components/FolderEnterprise/RegistroNuevaEmpresaComponent.vue"
        ),
    },
  };
  </script>
  
  <style></style>
  